<template>
  <div>
    <b-row align-v="stretch">
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Enquetes criadas"
            popover="Enquetes criadas"
        >{{surveyCount}}</dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Usuários participantes"
            popover="Usuários participantes"
        >{{userCount}}</dashboard-big-number-a>
      </b-col>
      <b-col class="mb-3" cols="12" md="6" lg="3">
        <dashboard-big-number-a
            class="h-100"
            title="Taxa de participação"
            popover="Taxa de participação"
        >{{participationRate}}</dashboard-big-number-a>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-for="(item, index) in surveyList" :key="'survey-'+index">
      <b-col>
        <dashboard-quiz-response
            :title="'Enquete '+prefixZero(index+1)"
            :tag="item.tag"
            :question="item.question"
            :answers="item.answers"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DashboardBigNumberA from '@/components/dashboards/dashboard-big-number-a'
import DashboardQuizResponse from '@/components/dashboards/dashboard-quiz-response'
import { pollsMetrics } from '@/modules/dashboards/http/pollsMetrics'
import { pollsResults } from '@/modules/dashboards/http/pollsResults'

function prefixZero (num) {
  return (num + '').padStart(2, '0')// num < 10 ? '0' + num : num
}

export default {
  name: 'SurveyDetail',
  components: { DashboardQuizResponse, DashboardBigNumberA },
  data: function () {
    return {
      roomId: null,
      surveyCount: '',
      userCount: '',
      participationRate: '',
      surveyList: [],
      prefixZero
    }
  },
  computed: {
    filterDates () {
      return {
        startDate: this.$store.state.dashboards.filterStartDate,
        endDate: this.$store.state.dashboards.filterEndDates
      }
    }
  },
  methods: {
    fetchData () {
      this.roomId = this.$route.params.id
      Promise.allSettled([
        this.fetchMetrics(),
        this.fetchResult()
      ])
    },
    async fetchMetrics () {
      const data = await pollsMetrics(this.roomId, this.filterDates)
      this.surveyCount = data.quantidade
      this.userCount = data.participantes
      this.participationRate = Math.round(data.taxa * 100) + '%'
    },
    async fetchResult () {
      const data = await pollsResults(this.roomId, this.filterDates)
      this.surveyList = data.map(item => ({
        tag: item.answers.length + ' RESPOSTAS' + (item.answers.length > 1 ? 'S' : ''),
        question: item.question,
        answers: item.answers.map(item => ({
          value: item.option,
          percentage: item.porcentagem,
          total: item.total
        }))
      }))
    }
  },
  watch: {
    filterDates: {
      handler () {
        this.fetchData()
      },
      deep: true
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      { immediate: true }
    )
  }
}
</script>

<style scoped>

</style>
