<template>
  <dashboard-card-collapsable
      :title="title"
      :tag="tag"
  >
    <div class="dq-answers">
      <div class="dq-question-text">
        {{question}}
      </div>
      <div class="dq-question-answers-list">
        <div v-for="(item, index) in answers" :key="'dq-question-answer-'+index" class="dq-question-answer">
          <div class="dq-question-answer-text" :class="{'dq-question-answer-text-correct': item.correct}">
            <span>{{ item.value }}</span>
          </div>
          <div class="dq-question-answer-tag">
            <div class="dq-question-answer-tag-bar">
              <div class="dq-question-answer-tag-bar-content" :style="{width: item.percentage+'%'}"></div>
            </div>
            {{ item.percentage }}% ({{ item.total}})
          </div>
        </div>
      </div>
    </div>
  </dashboard-card-collapsable>
</template>

<script>
import DashboardCardCollapsable from '@/components/dashboards/dashboard-card-collapsable'
export default {
  name: 'dashboard-quiz-response',
  props: {
    title: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: true
    },
    question: {
      type: String,
      required: true
    },
    answers: {
      type: Array,
      required: true
    }
  },
  components: { DashboardCardCollapsable }
}
</script>

<style scoped lang="scss">

.dq-answers {
  position: relative;

  .dq-question-text {
    font-size: 14px;
    font-weight: 600;
    color: #5F6368;
    padding: 16px 18px;
  }

  .dq-question-answers-list {
  }

  .dq-question-answer-text {
    margin-left: 1em;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #5F6368;
    span {
      margin-right: 1.5rem;
    }
    &.dq-question-answer-text-correct::after {
      content: 'Resposta correta';
      color: #21CD5B;
      font-size: 12px;
      font-weight: 400;
      padding: 6px 14px;
      background: #F4F6FC;
      border-radius: 42px;
      display: inline-block;
      word-break: break-all;
    }
  }

  .dq-question-answer-tag {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    color: #5F6368;
    align-items: center;
    margin-bottom: 28px;
  }

  .dq-question-answer-tag-bar {
    position: relative;
    width: calc(100% - 8em);
    height: 12px;
    margin-right: 1.5em;
    border-radius: 0 40px 40px 0;
    background-color: rgba(69, 80, 111, 0.19);
    .dq-question-answer-tag-bar-content {
      max-width: 100%;
      background-color: #5F6371;
      box-shadow: 0 4px 9px 2px rgba(95, 99, 113, 0.25);
      border-radius: 0 40px 40px 0;
      height: 100%;
    }
  }
}

</style>
