import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function pollsMetrics (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/polls-metrics`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return {
    quantidade: data.poll ?? 0,
    participantes: data.pollAnswers ?? 0,
    taxa: (data.rateParticipantPoll ?? 0) / 100
  }
}
