import http from '@/http'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

export async function pollsResults (roomId, { startDate, endDate } = {}) {
  const data = await http.get(`/admin/metrics/rooms/${roomId}/polls-results`, {
    params: {
      created_start: parseDataParam(startDate),
      created_end: parseDataParam(endDate)
    }
  }).then(response => response.data)
  return data.data.map(item => ({
    id: item.id,
    question: item.description,
    answers: item.results.map(({ porcentagem, total, option }) => ({ porcentagem, total, option }))
  }))
}
